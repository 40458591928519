/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const playMovie = /* GraphQL */ `
  query PlayMovie(
    $filter: TableDocopaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playMovie(filter: $filter, limit: $limit, nextToken: $nextToken) {
      resultCode
      method {
        id
        register_date
        description
        description_bedrock
        likes
        setting_thumbnail_url
        status
        subtags
        tag
        thumbnail_url
        title
        update_date
        video_time
        video_url
        views
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserInformation = /* GraphQL */ `
  query GetUserInformation($email: String!) {
    getUserInformation(email: $email) {
      userData {
        email
        bookmarked_videoid_list
        follow_list
        follower_list
        is_admin
        user_name
        profile_picture
        profile_text
        registered_videoid_list
        liked_videoid_list
        __typename
      }
      resultCode
      __typename
    }
  }
`;
export const getTagList = /* GraphQL */ `
  query GetTagList(
    $filter: TableTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTagList(filter: $filter, limit: $limit, nextToken: $nextToken) {
      data {
        id
        name
        __typename
      }
      resultCode
      __typename
    }
  }
`;
export const getSearchList = /* GraphQL */ `
  query GetSearchList(
    $update_date_from: String
    $update_date_to: String
    $tag: String
    $title: String
    $sort: Int
    $nextToken: String
  ) {
    getSearchList(
      update_date_from: $update_date_from
      update_date_to: $update_date_to
      tag: $tag
      title: $title
      sort: $sort
      nextToken: $nextToken
    ) {
      video_list {
        id
        register_date
        description
        description_bedrock
        likes
        setting_thumbnail_url
        status
        subtags
        tag
        thumbnail_url
        title
        update_date
        video_time
        video_url
        views
        __typename
      }
      video_count
      resultCode
      __typename
    }
  }
`;
export const getSubTagVideo = /* GraphQL */ `
  query GetSubTagVideo($subtag: String, $nextToken: String) {
    getSubTagVideo(subtag: $subtag, nextToken: $nextToken) {
      video_list {
        id
        register_date
        description
        description_bedrock
        likes
        setting_thumbnail_url
        status
        subtags
        tag
        thumbnail_url
        title
        update_date
        video_time
        video_url
        views
        __typename
      }
      video_count
      resultCode
      __typename
    }
  }
`;
export const getViewHistory = /* GraphQL */ `
  query GetViewHistory($email: String!) {
    getViewHistory(email: $email) {
      viewHistory
      resultCode
      __typename
    }
  }
`;
export const getInformation = /* GraphQL */ `
  query GetInformation($id: Int!) {
    getInformation(id: $id) {
      data {
        id
        description
        is_use
        __typename
      }
      resultCode
      __typename
    }
  }
`;
export const getVideoByIdList = /* GraphQL */ `
  query GetVideoByIdList($idList: [Int]) {
    getVideoByIdList(idList: $idList) {
      video_list {
        id
        register_date
        description
        description_bedrock
        likes
        setting_thumbnail_url
        status
        subtags
        tag
        thumbnail_url
        title
        update_date
        video_time
        video_url
        views
        __typename
      }
      video_count
      resultCode
      __typename
    }
  }
`;
export const getStatus = /* GraphQL */ `
  query GetStatus($id: Int, $register_date: String) {
    getStatus(id: $id, register_date: $register_date) {
      status
      resultCode
      __typename
    }
  }
`;
export const getVideoList = /* GraphQL */ `
  query GetVideoList {
    getVideoList {
      data {
        id
        register_date
        description
        description_bedrock
        likes
        setting_thumbnail_url
        status
        subtags
        tag
        thumbnail_url
        title
        update_date
        video_time
        video_url
        views
        __typename
      }
      resultCode
      __typename
    }
  }
`;
export const getPlaylist = /* GraphQL */ `
  query GetPlaylist($id: Int!) {
    getPlaylist(id: $id) {
      data {
        id
        title
        description
        is_published
        playlist_videos
        published_user_email
        __typename
      }
      resultCode
      __typename
    }
  }
`;
export const getPlaylistItem = /* GraphQL */ `
  query GetPlaylistItem($playlist_id: Int!) {
    getPlaylistItem(playlist_id: $playlist_id) {
      data {
        playlist_id
        video_id
        custom_description
        custom_title
        __typename
      }
      resultCode
      __typename
    }
  }
`;
